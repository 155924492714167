import axios, { AxiosHeaders } from "axios";
import {
  ApiResult,
  AuthResponse,
  ApiFailure,
  ApiSuccess,
  TokenPair,
} from "../types/apiTypes";
import { ResponsibleUserService } from "./ResponsibleUserService";
// import { jwtDecode } from "jwt-decode";

export const baseURL = window._env_.REACT_APP_API_URL;
const RESPONSIBLE_USER_HEADER = "X-Responsible-User-Id";

export const API_PATHS = {
  getCalendarDates: "/retrieve-planning-dates",
  initiateNexusPlan: "/start-nexus-data-extraction",
  getNexusFilters: "/retrieve-nexus-filters",
  getActiveNexusFilters: "/retrieve-active-nexus-filters",
  getNexusPlan: "/gantt-chart-data",
  getCitizenKPIList: "/todo:addhere",
  retrieveKPIs: "/dashboard-data",
  retrievePondooStatus: "/jobs/state",
  startOptimization: "/start-optimization",
} as const;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(function (config) {
  config.headers.Authorization = "Basic cWFtdXNlcjpxYW1wYXNzd29yZA==";
  config.headers["X-Responsible-User-Id"] = 1; // TODO: Temporary fix
  return config;
});

export const setupAuthInterceptor = (bearerToken: string) => {
  // Clear existing interceptors if any
  api.interceptors.request.clear();

  api.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${bearerToken}`;

    const responsibleUserId =
      ResponsibleUserService.getInstance().getResponsibleUserId();
    if (responsibleUserId) {
      config.headers[RESPONSIBLE_USER_HEADER] = responsibleUserId;
    }

    return config;
  });
};

export const setupRefreshTokenInterceptor = (refreshToken: string) => {
  api.interceptors.response.clear();
  api.interceptors.response.use(
    (response) => {
      const xResponsibleUserId = (response.headers as AxiosHeaders).get(
        RESPONSIBLE_USER_HEADER,
      );
      if (xResponsibleUserId) {
        ResponsibleUserService.getInstance().setResponsibleUserId(
          xResponsibleUserId as string,
        );
      }

      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const tokens = await AuthService.refreshConcreteToken(refreshToken);

          // Reset the request interceptors
          setupAuthInterceptor(tokens?.accessToken!);
          setupRefreshTokenInterceptor(tokens?.refreshToken!);

          originalRequest.headers["Authorization"] =
            `Bearer ${tokens?.accessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          console.error(refreshError);
          localStorage.clear();
          AuthService.redirectToLogin();
          return Promise.reject(refreshError);
        }
      }
    },
  );
};

export class AuthService {
  static tokenURL = window._env_.REACT_APP_TOKEN_URL;
  static loginURL = window._env_.REACT_APP_LOGIN_URI;
  static clientId = window._env_.REACT_APP_CLIENT_ID;
  static clientSecret = window._env_.REACT_APP_CLIENT_SECRET;

  static headers: Record<string, string> = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  static redirectToLogin = () => (window.location.href = this.loginURL);

  static refreshConcreteToken = async (
    refreshToken: string,
  ): Promise<TokenPair | undefined> => {
    const params = new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: refreshToken as string,
      client_id: this.clientId,
      client_secret: this.clientSecret,
    });

    // Call token refresh endpoint using fetch, to avoid triggering an infinite loop in the Axios intercept handler
    const response = await fetch(this.tokenURL, {
      method: "POST",
      headers: this.headers,
      body: params,
    });

    if (response.status === 200) {
      const data = await response.json();
      return this.setAuthResponseInLocalStorageAndReturnToken({
        success: true,
        data: data,
      });
    } else {
      console.error("Could not retrieve an authorization token");
      this.redirectToLogin();
    }
  };

  private static setAuthResponseInLocalStorageAndReturnToken = (
    authResponse: ApiSuccess<AuthResponse>,
  ): TokenPair => {
    const { access_token, expires_in, refresh_token, refresh_expires_in } =
      authResponse.data;

    localStorage.setItem("access_token", access_token);
    localStorage.setItem("access_token_expires_in", expires_in);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("refresh_expires_in", refresh_expires_in);

    return { accessToken: access_token, refreshToken: refresh_token };
  };
}

const isExpired = (expiry: Date, now: Date): boolean => {
  const expiryTime = expiry.getTime();
  const nowTime = now.getTime();

  if (expiryTime - nowTime <= 0) return true;

  return (expiryTime - nowTime) / (60 * 1000) <= 1; // If we have less than one minute until expiration, renew the token
};

export default api;
