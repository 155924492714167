import { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import labels from "../../utils/labels";
import TabButton from "../UI/buttons/TabButton";
import InfoFieldTextBoxGreyBlackColumns from "./InfoFieldTextBoxGreyBlackColumns";
import VisitHoursWrapper from "./VisitHoursWrapper";
import ColouredRoundWrapper from "../UI/ColouredRoundWrapper";
import SubTasksList from "./SubTasksList";
import DrawerBlock from "./DrawerBlock";
import drawerStyles from "../../styles/drawer.module.css";
import EmployeePreferences from "./EmployeePreferences";
import { createTaskDependencyRenderingData } from "../../utils/taskDependencyUtils";
import TaskDependenciesList from "./TaskDependenciesList";
import ViolationBox from "./ViolationBox";

type TaskBarType = "VISIT" | "CITIZEN";

type TaskBarObject = { title: string; type: TaskBarType };

const DrawerContent = () => {
  const taskToShow = useAppSelector((state) => state.modalSlice.taskToShow);
  const dependencyMap = useAppSelector(
    (state) => state.ganttChartSlice.taskDependencyMap
  )!;
  const dependencyRenderingData = createTaskDependencyRenderingData(
    taskToShow?.task!,
    dependencyMap
  );
  const [selected, setSelected] = useState<TaskBarType>("VISIT");
  const tabBarValues: TaskBarObject[] = [
    { title: labels.visit, type: "VISIT" },
    { title: labels.citizen, type: "CITIZEN" },
  ];

  return (
    <>
      {taskToShow && (
        <div className={drawerStyles.drawerContent}>
          <ColouredRoundWrapper bgColor="var(--col-grey-1)" width="100%">
            {tabBarValues.map((tab, index) => (
              <TabButton
                key={index}
                full
                value={
                  tab.type === "VISIT" ? labels.visitDetails : labels.employees
                }
                onClick={() => setSelected(tab.type)}
                selected={
                  selected === "VISIT" ? labels.visitDetails : labels.employees
                }
              ></TabButton>
            ))}
          </ColouredRoundWrapper>
          {taskToShow.task.violations.length > 0 && (
            <ViolationBox violations={taskToShow.task.violations} />
          )}
          <DrawerBlock title={labels.citizenDetails}>
            <InfoFieldTextBoxGreyBlackColumns
              key={labels.citizen}
              firstColumn={labels.citizen}
              secondColumn={taskToShow.task.client.description}
            />

            <InfoFieldTextBoxGreyBlackColumns
              key={labels.adress}
              firstColumn={labels.adress}
              secondColumn={
                <>
                  <span>{taskToShow.task.address}</span>
                  <span>
                    {taskToShow.task.postalCode} {taskToShow.task.district}
                  </span>
                </>
              }
            />

            <InfoFieldTextBoxGreyBlackColumns
              key={labels.priority}
              firstColumn={labels.priority}
              secondColumn={
                taskToShow.task.priority === "HIGH"
                  ? labels.high
                  : labels.normal
              }
            />
          </DrawerBlock>

          <DrawerBlock title={labels.skillRequirement}>
            <InfoFieldTextBoxGreyBlackColumns
              key={labels.skills}
              firstColumn={labels.skills}
              secondColumn={taskToShow.task.requiredSkills}
            />
          </DrawerBlock>
          <div className="grey-line"></div>
          {selected === "VISIT" && (
            <VisitHoursWrapper task={taskToShow.task!} />
          )}
          {selected === "VISIT" && <SubTasksList task={taskToShow.task!} />}
          {selected === "VISIT" && (
            <TaskDependenciesList
              taskDependencyRenderingData={dependencyRenderingData}
            />
          )}
          {selected === "CITIZEN" && (
            <EmployeePreferences
              preferences={taskToShow.task.preferences}
              employeeName={taskToShow.employeeName}
            />
          )}
        </div>
      )}
    </>
  );
};

export default DrawerContent;
