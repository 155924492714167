import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  hideTaskHoverBox,
  openDrawer,
  showTaskHoverBox,
} from "../../../store/drawer";
import { Employee, WorkTask } from "../../../types/ganttChart";
import taskStyles from "../../../styles/ganttChart/tasks.module.css";

const VerticalOperationalTask = ({
  xPosition,
  yPosition,
  width,
  task,
  height,
  employee,
}: {
  xPosition: number;
  yPosition: number;
  width: number;
  task: WorkTask;
  height?: number;
  employee?: Employee;
}) => {
  const dispatch = useAppDispatch();
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice,
  );
  const highlightedTaskIds = useAppSelector(
    (state) => state.searchSlice.highlightedTaskIds,
  );
  const getOpacity = () => {
    const isHighlightedOrEverythingShouldBeVisible =
      highlightedTaskIds.includes(task.idTask) ||
      highlightedTaskIds.length === 0;
    return isHighlightedOrEverythingShouldBeVisible ? 1 : 0.2;
  };
  return (
    <>
      {verticalColumnWidth && height && (
        <>
          <foreignObject
            x={xPosition + 2}
            y={yPosition}
            width={width}
            height={height}
            onMouseEnter={() => {
              dispatch(
                showTaskHoverBox({
                  type: "OPERATIONAL",
                  task: task,
                  employee: employee,
                }),
              );
            }}
            onMouseLeave={() => {
              dispatch(hideTaskHoverBox());
            }}
          >
            <div
              onClick={() => {
                dispatch(
                  openDrawer({ task: task, employeeName: employee?.fullName }),
                );
              }}
              className={taskStyles.verticalAuxiliaryTask}
              style={{ opacity: getOpacity() }}
            >
              <p className={taskStyles.verticalTaskText}>{task.description}</p>
            </div>
          </foreignObject>
        </>
      )}
    </>
  );
};

export default VerticalOperationalTask;
