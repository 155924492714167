import { Fragment, useEffect, useState } from "react";
import ganttChartService from "../../../service/ganttChartService";
import { useAppSelector } from "../../../store/hooks";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import VerticalWorkTask from "./VerticalWorkTask";
import UnplannedTaskChartPositionAllocationService, {
  GroupedTask,
} from "../../../service/UnplannedTaskChartPositionAllocationService";
import { UnplannedTasksType } from "../../../types/ganttChart";

const UnplannedTasks = ({
  unplannedTasks,
}: {
  unplannedTasks: UnplannedTasksType;
}) => {
  const { verticalColumnWidth, ganttChartData } = useAppSelector(
    (state) => state.ganttChartSlice,
  );
  const { timelineRange, pixelPerMinute } = useAppSelector(
    (state) => state.timelineSlice,
  );
  const [unplanned, setUnplanned] = useState<GroupedTask[]>();
  const [taskWidthPx, setTaskWidthPx] = useState<number>();

  useEffect(() => {
    if (!timelineRange) return;
    // Separate tasks into full-day tasks and shorter tasks which need to be laid out
    const separatedTasks =
      UnplannedTaskChartPositionAllocationService.separateFullDayTasks(
        unplannedTasks.workTasks,
        timelineRange,
      );
    // TODO @Tanja : Also draw the full day tasks
    const groupedTasks =
      UnplannedTaskChartPositionAllocationService.clusterOverlappingTasks(
        separatedTasks.shorterTasks,
      );
    // Currently we filter out all Gantt Chart positions > 10 for space reasons. These remaining tasks should be
    // collapsed into one task with an elaborate on-hover functionality. TODO
    setUnplanned(groupedTasks.filter((task) => task.ganttChartPosition < 10));
    setTaskWidthPx(
      ganttChartVerticalValues.getTaskWidth(verticalColumnWidth) /
        Math.min(
          10,
          Math.max(...groupedTasks.map((task) => task.ganttChartPosition)),
        ),
    );
  }, [verticalColumnWidth, unplannedTasks, timelineRange, ganttChartData]);

  return (
    <>
      {ganttChartData &&
        timelineRange &&
        unplanned &&
        unplanned.map((task, index) => (
          <Fragment key={"nexus-unplanned" + index}>
            <VerticalWorkTask
              xPosition={
                ganttChartVerticalValues.verticalViewHeaderColumn +
                taskWidthPx! * task.ganttChartPosition
              }
              task={task}
              yPosition={ganttChartVerticalValues.findTaskYPosition(
                timelineRange!,
                pixelPerMinute,
                task.start,
              )}
              width={taskWidthPx!}
              height={
                ganttChartService.timeDifferenceInMinutes(
                  task.start,
                  task.end,
                ) * pixelPerMinute
              }
            />
          </Fragment>
        ))}
    </>
  );
};

export default UnplannedTasks;
