import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ganttChartService from "../../../service/ganttChartService";
import {
  Employee,
  TransportationTask,
  TransportationType,
} from "../../../types/ganttChart";
import VerticalGanttChartTransportationIcon from "./VerticalGanttChartTransportationIcon";
import taskStyles from "../../../styles/ganttChart/tasks.module.css";
import { hideTaskHoverBox, showTaskHoverBox } from "../../../store/drawer";

const VerticalGanttChartTransportationTask = ({
  xPosition,
  yPosition,
  width,
  task,
  transportationType,
  employee,
}: {
  xPosition: number;
  yPosition: number;
  width: number;
  task: TransportationTask;
  transportationType: TransportationType;
  employee: Employee;
}) => {
  const dispatch = useAppDispatch();
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice
  );
  const { pixelPerMinute } = useAppSelector((state) => state.timelineSlice);
  const [taskHeight, setTaskHeight] = useState<number>(0);
  const iconHeight = 16;

  useEffect(() => {
    if (pixelPerMinute) {
      setTaskHeight(
        ganttChartService.timeDifferenceInMinutes(task.start, task.end) *
          pixelPerMinute
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pixelPerMinute]);

  return (
    <>
      {verticalColumnWidth && taskHeight && (
        <>
          {
            <foreignObject
              className="relative"
              x={xPosition+2}
              y={yPosition}
              width={width}
              height={taskHeight}
              onMouseEnter={() => {
                dispatch(
                  showTaskHoverBox({
                    type: "TRANSPORTATION_TASK",
                    task: task,
                    employee: employee,
                  })
                );
              }}
              onMouseLeave={() => {
                dispatch(hideTaskHoverBox());
              }}
            >
              <div
                style={{
                  height: taskHeight,
                  width: width,
                }}
                className={taskStyles.transportationTask}
              >
                {iconHeight < taskHeight && (
                  <VerticalGanttChartTransportationIcon
                    type={transportationType}
                  />
                )}
              </div>
            </foreignObject>
          }
        </>
      )}
    </>
  );
};

export default VerticalGanttChartTransportationTask;
