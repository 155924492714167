import { useAppDispatch } from "../../store/hooks";
import { setSelectedNexusFilterDate } from "../../store/nexusFilters";
import dateUtil from "../../utils/dummeDate";
import DatePicker from "../UI/DatePicker";

const HeaderCalendar = ({
  setDateSearchParam,
}: {
  setDateSearchParam: (date: string) => void;
}) => {
  const dispatch = useAppDispatch();
  const todaysDate = new Date();

  const calculateMinDateValue = () => {
    const oneDayBeforeToday = new Date();
    oneDayBeforeToday.setDate(todaysDate.getDate() - 7);
    return dateUtil.getCalendarValueString(oneDayBeforeToday);
  };

  const calculateMaxDateValue = () => {
    const TenDaysAheadOfToday = new Date();
    TenDaysAheadOfToday.setDate(todaysDate.getDate() + 14);
    return dateUtil.getCalendarValueString(TenDaysAheadOfToday);
  };

  return (
    <DatePicker
      showArrows
      min={calculateMinDateValue()}
      max={calculateMaxDateValue()}
      setSelectedDate={(date: string) => {
        dispatch(setSelectedNexusFilterDate(date));
        setDateSearchParam(date);
      }}
      bgColor="white"
      color="var(--col-black)"
    />
  );
};

export default HeaderCalendar;
