import { Fragment, useEffect, useState } from "react";
import timestampService from "../../service/timestampService";
import { WorkTask } from "../../types/ganttChart";
import timelineService from "../../service/timelineService";
import ganttChartService from "../../service/ganttChartService";
import drawerStyles from "../../styles/drawer.module.css";

const VisitHoursTimeline = ({
  task,
  svgWidth,
}: {
  task: WorkTask;
  svgWidth: number;
}) => {
  const [timeline, setTimeline] = useState<Date[]>();
  const [pixelsPerMinute, setPixelPerMinute] = useState<number>();

  const [timelineDurationHours, setTimelineDurationHours] = useState<number>();

  const getEarliestTimePoint = (): string => {
    return task.preferredEarliestStart ?? task.start;
  };

  const getLastestTimePoint = (): string => {
    if (
      task.preferredLatestEnd &&
      new Date(task.preferredLatestEnd).getTime() > new Date(task.end).getTime()
    )
      return task.preferredLatestEnd;
    else return task.end;
  };

  const getTimeline = () => {
    const timeline: Date[] = [];
    const timeInterval = 30 * 60000; //half an hour
    let nextTimePoint = new Date(getEarliestTimePoint()).getTime(); //- timeInterval;

    const latestTimePointInMiliseconds = new Date(
      getLastestTimePoint(),
    ).getTime();

    timeline.push(timestampService.getRoundedDate(new Date(nextTimePoint)));

    while (nextTimePoint < latestTimePointInMiliseconds) {
      nextTimePoint = nextTimePoint + timeInterval;
      timeline.push(timestampService.getRoundedDate(new Date(nextTimePoint)));
    }
    nextTimePoint = nextTimePoint + timeInterval;
    timeline.push(timestampService.getRoundedDate(new Date(nextTimePoint)));
    setTimeline(timeline);
  };

  /**
   * Determines the millisecond time interval to use as gaps between rendered timestamps
   * @returns
   */
  const calculateTimeDeltaHours = (): number => {
    const earliestTimePoint = getEarliestTimePoint();
    const latestTimePoint = getLastestTimePoint();

    const timeDeltaMilliseconds =
      new Date(latestTimePoint).getTime() -
      new Date(earliestTimePoint).getTime();
    return timeDeltaMilliseconds / (1000 * 60 * 60);
  };

  /**
   * In the case of very long timelines, we apply a heuristic to hide every nth timepoint to reduce
   * visual clutter
   * @param index
   * @returns
   */
  const shouldDisplayTimePoint = (index: number): boolean => {
    if (!timelineDurationHours) return true; // Keep all time points if we don't know the bounds for whatever reason

    if (timelineDurationHours < 12) return true;

    // Hide things that will be hidden by the edges of the SVG container
    if (index < 2 || index >= timeline?.length! - 1) return false;

    // If the timeline is longer than 12 hours, paint only every fourth date
    return index % 5 === 0;
  };

  useEffect(() => {
    setTimelineDurationHours(calculateTimeDeltaHours());
    getTimeline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (svgWidth && timeline) {
      const timelineDuration =
        timeline[timeline.length - 1].getTime() - timeline[0].getTime();
      setPixelPerMinute(svgWidth / (timelineDuration / 60000));
    }
  }, [svgWidth, timeline]);

  const getXPositionForTimeBlock = (start: string) => {
    const distanceFromStart =
      (new Date(start).getTime() - timeline![0].getTime()) / 60000;

    return distanceFromStart * pixelsPerMinute!;
  };

  return (
    <>
      {pixelsPerMinute && (
        <svg width={svgWidth} height={65}>
          <g>
            <rect x={0} y={22} width={svgWidth} height={44} fill="white"></rect>
            <title> ={svgWidth}</title>
            <line
              x1={0}
              x2={svgWidth}
              y1={22}
              y2={22}
              stroke="var(--col-grey-2)"
            />
          </g>
          <foreignObject x={0} y={0} width={svgWidth} height={20}>
            <div
              className="flex"
              style={{
                position: "absolute",
                left: "-19px",
                height: "20px",
                width: "100%",
              }}
            >
              {timeline &&
                timeline.map((timepoint, index) => (
                  <p
                    className={drawerStyles.timelineTimepoints}
                    key={timepoint.getTime()}
                    style={{
                      minWidth: pixelsPerMinute * 30,
                      visibility: shouldDisplayTimePoint(index)
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    {timestampService.getHoursAndMinutesTimestamp(timepoint)}
                  </p>
                ))}
            </div>
          </foreignObject>
          {timeline &&
            timeline.map((timepoint, index) => (
              <Fragment key={index}>
                <line
                  x1={index * pixelsPerMinute * 30}
                  x2={index * pixelsPerMinute * 30}
                  y1={18}
                  y2={64}
                  stroke="var(--col-grey-2)"
                  style={{
                    visibility: shouldDisplayTimePoint(index)
                      ? "visible"
                      : "hidden",
                  }}
                />
              </Fragment>
            ))}
          {/* PreferredTime */}
          <rect
            x={getXPositionForTimeBlock(task.preferredEarliestStart)}
            y={35}
            width={
              ganttChartService.timeDifferenceInMinutes(
                task.preferredEarliestStart,
                task.preferredLatestEnd,
              ) * pixelsPerMinute
            }
            height={14}
            stroke="var(--col-lavendar)"
            strokeWidth="1"
            fill="var(--col-lavendar-light)"
            rx="2px"
          ></rect>
          {/* PlannedTime */}
          <rect
            x={getXPositionForTimeBlock(task.start)}
            y={36}
            width={
              parseInt(timelineService.returnDurationInMinutes(task.duration)) *
              pixelsPerMinute
            }
            height={12}
            fill="var(--col-lavendar-dark)"
            rx="2px"
          ></rect>
        </svg>
      )}
    </>
  );
};

export default VisitHoursTimeline;
