import { createAsyncThunk } from '@reduxjs/toolkit';
import api, { API_PATHS } from '../../service/api';

enum actionTypes {
	initiateGanttChartData = 'initiateGanttChartData ',
	initiateOptimization = 'initiateOptimization ',
	getGanttChartData = 'getGanttChartData ',
	getPondooStatus = 'getPondooStatus',
	updatePondooStatus = 'updatePondooStatus',
}

type GetGanttChartDataParams = {
	idActiveNexusFilter: number;
	idPlanningDate: number;
	pondooSolution: boolean;
};

type InitieGanttChartDataParams = {
	filterId: number;
	date: string;
};

type GetPondooStatusParams = {
	idActiveNexusFilter: number;
	planningDate: string;
	lastModified: string;
};

// type InitiatePondooParams = {
//   idScenario: number
// };

export const initiateGanttChartData = createAsyncThunk(
	actionTypes.initiateGanttChartData,
	async (params: InitieGanttChartDataParams) => {
		const data = {
			idActiveNexusFilter: params.filterId,
			planningDate: params.date.split('T')[0],
		};
		const response = await api.post<any>(API_PATHS.initiateNexusPlan, data);
		return response.data;
	}
);

export const initiateOptimization = createAsyncThunk(actionTypes.initiateOptimization, async (param: number) => {
	const data = {
		idScenario: param,
	};
	const response = await api.post<any>(API_PATHS.startOptimization, data);
	return response.data;
});

export const getGanttChartData = createAsyncThunk(
	actionTypes.getGanttChartData,
	async (params: GetGanttChartDataParams) => {
		const response = await api.get<any>(
			`${API_PATHS.getNexusPlan}?idActiveNexusFilter=${params.idActiveNexusFilter}&idPlanningDate=${params.idPlanningDate}&pondooSolution=${params.pondooSolution}`
		);
		return response.data;
	}
);

let requestRunning: boolean = false;
const milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection = 15000;

export const getPondooStatus = createAsyncThunk(
	actionTypes.getPondooStatus,
	async (params: GetPondooStatusParams, thunkAPI) => {
		if (!requestRunning && params.idActiveNexusFilter && params.planningDate) {
			requestRunning = true;
			try {
				const response = await api.get<any>(
					`${API_PATHS.retrievePondooStatus}?idActiveNexusFilter=${params.idActiveNexusFilter}&fullState=true&planningDate=${params.planningDate}`
				);
				const lastModifiedHeader = response.headers['last-modified'];
				params.lastModified = lastModifiedHeader;
				requestRunning = false;

				if (
					response.data.data.jobStatus === 'Running' ||
					response.data.data.jobStatus === 'NotScheduled' ||
					response.data.data.jobStatus === 'Scheduled'
				) {
					thunkAPI.dispatch(updatePondooStatus(params));
				}
				return response.data;
			} catch (error: any) {
				requestRunning = false;
				setTimeout(() => {
					thunkAPI.dispatch(getPondooStatus(params));
				}, milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection);
				throw error;
			}
		}
	}
);

export const updatePondooStatus = createAsyncThunk(
	actionTypes.updatePondooStatus,
	async (params: GetPondooStatusParams, { dispatch }) => {
		const headers = {
			'If-Modified-Since': params.lastModified, // Add If-Modified-Since header to query for new data
		};
		if (!requestRunning && params.idActiveNexusFilter && params.planningDate) {
			requestRunning = true;
			try {
				const response = await api.get<any>(
					`${API_PATHS.retrievePondooStatus}?idActiveNexusFilter=${params.idActiveNexusFilter}&fullState=false&planningDate=${params.planningDate}`,
					{
						headers,
					}
				);
				const lastModifiedHeader = response.headers['last-modified'];
				requestRunning = false;

				if (response.status === 200) {
					if (
						response.data.data.jobStatus === 'Running' ||
						response.data.data.jobStatus === 'NotScheduled' ||
						response.data.data.jobStatus === 'Scheduled'
					) {
						params.lastModified = lastModifiedHeader;
						dispatch(updatePondooStatus(lastModifiedHeader));
					}
					return response.data;
				}
			} catch (error: any) {
				requestRunning = false;
				if (error && (!error.response || error.response.status >= 400)) {
					setTimeout(() => {
						dispatch(getPondooStatus(params));
					}, milisecondsBeforeReestablishingConnectionToBackendAfterLostConnection);
					throw error;
				}

				if (error.response.status === 304) {
					dispatch(updatePondooStatus(params));
					return;
				}
			}
		}
	}
);
