import { WorkTask } from "../../types/ganttChart";
import drawerStyles from "../../styles/drawer.module.css";
import GreyOpacityText from "../UI/textFields/GreyOpacityText";
import timestampService from "../../service/timestampService";
import labels from "../../utils/labels";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import { useAppSelector } from "../../store/hooks";
import { RuleConcordanceMessageFactory } from "../../utils/violationUtils";

const TaskDependencyBox = ({ task }: { task: WorkTask }) => {
  const employeeByTaskIdMap = useAppSelector(
    (state) => state.ganttChartSlice.employeeByTaskIdMap,
  );
  const employeeName = employeeByTaskIdMap![task.idTask].fullName;
  const className = `${drawerStyles.taskDependency} ${task.violations.length > 0 && drawerStyles.hasViolations}`;
  const ruleConcordanceMessageFactory = new RuleConcordanceMessageFactory(task);
  const ruleConcordanceMessages = ruleConcordanceMessageFactory.getMessages();

  return (
    <section className={className}>
      {task.violations.length > 0 && (
        <div className="flex gap-[--spacing-sm] items-center mb-2">
          <WarningIcon width={14} height={14} />
          <p>Regelbrud</p>
        </div>
      )}
      <div className={drawerStyles.taskInfoContainer}>
        <h5 className={drawerStyles.employeeName}>{employeeName}</h5>
        <div className={drawerStyles.taskTimeContainer}>
          <div className={drawerStyles.taskTimeContainerTimeColumn}>
            <GreyOpacityText size="MEDIUM">
              {labels.startTimeColumnLabel}
            </GreyOpacityText>
            <p>{timestampService.getHoursAndMinutesTimestamp(task.start)}</p>
          </div>
          <div className={drawerStyles.taskTimeContainerTimeColumn}>
            <GreyOpacityText size="MEDIUM">
              {labels.endTimeColumnLabel}
            </GreyOpacityText>
            <p>{timestampService.getHoursAndMinutesTimestamp(task.end)}</p>
          </div>
        </div>
      </div>
      {/* Violations */}
      {ruleConcordanceMessages.map((violation, idx) => (
        <p key={idx}>{violation}</p>
      ))}
    </section>
  );
};

export default TaskDependencyBox;
