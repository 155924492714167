import { useAppSelector } from "../../../store/hooks";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import UnplannedTasks from "./UnplannedTasks";
import VerticalGanttChartTaskWrapper from "./VerticalGanttChartTaskWrapper";
import { Fragment, useEffect } from "react";

const VerticalGanttChartContent = () => {
  const { verticalColumnWidth, ganttChartData } = useAppSelector(
    (state) => state.ganttChartSlice,
  );

  const { timelineRange } = useAppSelector((state) => state.timelineSlice);

  return (
    <>
      {/* Unplanned tasks */}
      {ganttChartData && timelineRange && (
        <UnplannedTasks
          unplannedTasks={ganttChartData.generalGanttChartInfo.unplannedTasks}
        />
      )}
      {/* Planned Tasks */}
      {ganttChartData &&
        timelineRange &&
        ganttChartData.employees.map((employee, index) => (
          <Fragment key={index}>
            <VerticalGanttChartTaskWrapper
              key={`tasks-${index}`}
              employee={employee}
              xPosition={
                verticalColumnWidth >=
                ganttChartVerticalValues.verticalViewMinColumnWidth
                  ? ganttChartVerticalValues.verticalViewHeaderColumn +
                    (index + 1) * verticalColumnWidth
                  : ganttChartVerticalValues.verticalViewHeaderColumn +
                    (index + 1) *
                      ganttChartVerticalValues.verticalViewMinColumnWidth
              }
            />
          </Fragment>
        ))}
    </>
  );
};

export default VerticalGanttChartContent;
