import { useEffect, useState } from "react";
import datePickerStyles from "../../styles/UI/date.module.css";
import IconButtonComponent from "./buttons/IconButton";
import { ReactComponent as BlackArrowRight } from "../../assets/icons/black-arrow-right.svg";
import dateUtil from "../../utils/dummeDate";

const DatePicker = ({
  min,
  max,
  showArrows,
  setSelectedDate,
  bgColor,
  color,
}: {
  min?: string;
  max?: string;
  showArrows?: boolean;
  setSelectedDate: (date: string) => void;
  bgColor?: string;
  color?: string;
}) => {
  const [guiDateValue, setGuiDateValue] = useState<any>(
    dateUtil.getCalendarDate(new Date())
  );
  const updateSelectedDate = (value: string) => {
    setInputDateValue(value);
    setGuiDateValue(dateUtil.getCalendarDate(new Date(value)));
  };

  const getDateValueFromISOString = (dateToTransform: string) => {
    const dateObject = new Date(dateToTransform);
    return dateUtil.getCalendarValueString(dateObject);
  };

  const [inputDateValue, setInputDateValue] = useState<any>(
    getDateValueFromISOString(new Date().toISOString())
  );
  useEffect(() => {
    setSelectedDate(new Date(inputDateValue).toISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputDateValue]);

  const moveXDay = (numberOfMoves: number) => {
    const dayBeforeCurrentlySelectedDay = new Date(inputDateValue);
    dayBeforeCurrentlySelectedDay.setDate(
      dayBeforeCurrentlySelectedDay.getDate() + numberOfMoves
    );
    updateSelectedDate(
      dateUtil.getCalendarValueString(dayBeforeCurrentlySelectedDay)
    );
  };

  return (
    <div className="flex jutify-center">
      {showArrows && (
        <IconButtonComponent
          key="moveBackwardIcon"
          id="moveBackwardIcon"
          icon={<BlackArrowRight style={{ transform: "rotate(180deg)" }} />}
          disable={inputDateValue === min}
          onClick={() => moveXDay(-1)}
        />
      )}
      <div className={datePickerStyles.datepickerWrapper}>
        <input
          style={{ backgroundColor: bgColor ? bgColor : "white" }}
          min={min}
          max={max}
          type="date"
          value={inputDateValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateSelectedDate(event.target.value)
          }
        />
        <p
          className={datePickerStyles.datepickerSubstitute}
          style={{
            backgroundColor: bgColor ? bgColor : "white",
            color: color ? color : "white",
          }}
        >
          {guiDateValue}
        </p>
      </div>
      {showArrows && (
        <IconButtonComponent
          key="moveForwardIcon"
          id="moveForwardIcon"
          icon={<BlackArrowRight />}
          disable={inputDateValue === max}
          onClick={() => moveXDay(1)}
        />
      )}
    </div>
  );
};
export default DatePicker;
