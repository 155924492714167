import { WorkTaskPreference } from "../../types/ganttChart";
import drawerStyle from "../../styles/drawer.module.css";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/check.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import labels from "../../utils/labels";
import DrawerBlock from "./DrawerBlock";
const EmployeePreferences = ({
  preferences,
  employeeName,
}: {
  preferences: WorkTaskPreference[];
  employeeName?: string;
}) => {
  return (
    <DrawerBlock title={labels.preferences}>
      <ul className={drawerStyle.preferenceList}>
        <li className={drawerStyle.preferenceItem}>
          <p
            className={`${drawerStyle.preferenceItemName} font-grey w-[200px]`}
          >
            {labels.employee}
          </p>
          <p
            className={`${drawerStyle.preferenceItemStatus} font-grey w-[150px]`}
          >
            {labels.locked}
          </p>
          <p
            className={`${drawerStyle.preferenceItemStatus} font-grey w-[100px]`}
          >
            {labels.competencies}
          </p>
          <p
            className={`${drawerStyle.preferenceItemStatus} font-grey w-[100px]`}
          >
            {labels.score}
          </p>
        </li>
        {preferences.map((preference) => (
          <li>
            {employeeName === preference.employeeName && (
              <p className="font-grey" style={{ font: "var(--font-body-3)" }}>
                {labels.assignedEmployee}
              </p>
            )}
            <div
              className={`${drawerStyle.preferenceItem}`}
              style={{
                backgroundColor:
                  employeeName === preference.employeeName
                    ? "var(--col-after-eight-30)"
                    : "none",
              }}
            >
              <p className={`${drawerStyle.preferenceItemName} w-[200px]`}>
                {preference.employeeName}
              </p>
              <div className={`${drawerStyle.preferenceItemStatus} w-[150px]`}>
                {!preference.lockedOnEmployee && (
                  <LockIcon width={24} height={24} />
                )}
              </div>
              <div className={`${drawerStyle.preferenceItemStatus} w-[100px]`}>
                {preference.hasCompetence && (
                  <CheckmarkIcon width={24} height={24} />
                )}
              </div>
              <div className={`${drawerStyle.preferenceItemStatus} w-[100px]`}>
                {preference.score}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </DrawerBlock>
  );
};

export default EmployeePreferences;
