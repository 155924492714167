import {
  EmployeeByTaskIdMap,
  GanttChartData,
  TaskDependencyMap,
  TaskDependencyRenderingData,
  WorkTask,
} from "../types/ganttChart";

/**
 * Create a data structure to quickly look up tasks and their dependencies
 * @param data
 * @returns
 */
const createTaskDependencyMap = (data: GanttChartData): TaskDependencyMap => {
  const tasks = data.employees.flatMap((employee) => employee.tasks.workTasks);

  const dependencyMap = tasks.reduce((map, task) => {
    map[task.idTask] = {
      dependsOnIds: [],
      isDependencyOfIds: [],
      task: task,
    };
    return map;
  }, {} as TaskDependencyMap);

  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];

    for (let j = 0; j < task.taskDependencies.length; j++) {
      const dependency = task.taskDependencies[j];

      const { idPrerequisiteTask, idDependentTask } = dependency;

      // If the task has no prerequisites, its idPrerequisiteTask will equal its idTask
      if (idPrerequisiteTask !== task.idTask)
        dependencyMap[task.idTask].dependsOnIds.push(idPrerequisiteTask);

      // If the task has no dependencies, its idDependency will equal its idTask
      if (idDependentTask !== task.idTask)
        dependencyMap[task.idTask].isDependencyOfIds.push(idDependentTask);
    }
  }

  return dependencyMap;
};

/**
 * Prepare task dependency data for rendering; given a task and a TaskDependencyMap, perform the necessary lookups to
 * access WorkTask objects
 * @param task
 * @param map
 * @returns
 */
export const createTaskDependencyRenderingData = (
  task: WorkTask,
  map: TaskDependencyMap,
): TaskDependencyRenderingData => {
  return task.taskDependencies.reduce<TaskDependencyRenderingData>(
    (data, dependency) => {
      if (task.idTask !== dependency.idPrerequisiteTask)
        data.dependsOn.push(map[dependency.idPrerequisiteTask].task);

      if (task.idTask !== dependency.idDependentTask)
        data.isDependencyOf.push(map[dependency.idDependentTask].task);
      return data;
    },
    { task: task, dependsOn: [], isDependencyOf: [] },
  );
};

export const createEmployeeByTaskIdMap = (
  data: GanttChartData,
): EmployeeByTaskIdMap => {
  return data.employees.reduce((map, employee) => {
    const { workTasks } = employee.tasks;
    for (let i = 0; i < workTasks.length; i++) {
      map[workTasks[i].idTask] = employee;
    }
    return map;
  }, {} as EmployeeByTaskIdMap);
};

export default createTaskDependencyMap;
