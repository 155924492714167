import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ganttChartStyles from "../../styles/ganttChart/ganttChart.module.css";
import VerticalGanttChartContent from "./verticalGanttChart/VerticalGanttChartContent";
import { useEffect, useRef, useState } from "react";
import useGanttChartPlacement from "../../hooks/useGanttChartPlacement";
import {
  setVerticalColumnWidth,
  setVerticalSvgWidth,
} from "../../store/ganttChart";
import SharedTimelineComponent from "./horizontalGanttChart/header/SharedTimelineComponent";
import VerticalTitleTextRow from "./verticalGanttChart/VerticalTitleTextRow";
import VerticalTextRows from "./verticalGanttChart/VerticalTextRows";
import ganttChartService from "../../service/ganttChartService";
import VerticalGanttChartSeparationLines from "./verticalGanttChart/VerticalGanttChartSeparationLines";
import HoverBox from "./hover/HoverBox";
import { hideTaskHoverBox } from "../../store/drawer";
import BackgroundColumns from "./verticalGanttChart/BackgroundColumns";
import { zoomInOnVerticalView } from "../../store/timeline";

const VerticalGanttWrapper = () => {
  const svgWrapper = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { windowWidth } = useGanttChartPlacement();
  const {
    verticalColumnWidth,
    verticalSvgWidth,
    translateXValueInPercent,
    ganttChartData,
  } = useAppSelector((state) => state.ganttChartSlice);
  const { pixelsPerHour, timelineRange, distanceFromTop } = useAppSelector(
    (state) => state.timelineSlice,
  );
  const { taskIsHovered } = useAppSelector((state) => state.modalSlice);
  const [containerWidthOffset, setContainerWidthOffset] = useState<number>(0);
  const [svgWrapperWidth, setSvgWrapperWidth] = useState<number>(0);
  const [isRun, setIsRun] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);

  useEffect(() => {
    if (timelineRange && pixelsPerHour && svgWrapper.current && !isRun) {
      svgWrapper.current.scrollTo({
        top: timelineRange.firstVisibleHour * pixelsPerHour - pixelsPerHour / 2,
        left: 0,
      });
      setIsRun(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineRange, svgWrapper.current, pixelsPerHour]);

  useEffect(() => {
    if (distanceFromTop && svgWrapper.current) {
      svgWrapper.current.scrollTo({
        top: distanceFromTop,
        left: 0,
      });
    }
  }, [distanceFromTop]);

  useEffect(() => {
    if (svgWrapper.current) {
      const verticalSvgWrapperWidth = svgWrapper.current?.clientWidth;
      setSvgWrapperWidth(verticalSvgWrapperWidth);
    }
  }, [windowWidth, svgWrapper.current?.clientWidth]);

  useEffect(() => {
    if (svgWrapper.current) {
      const verticalSvgWrapperWidth = svgWrapper.current.clientWidth;
      setSvgWrapperWidth(verticalSvgWrapperWidth);
      setContainerWidthOffset(
        svgWrapper.current.offsetWidth - svgWrapper.current.clientWidth,
      );
    }
  }, [windowWidth, svgWrapperWidth, verticalColumnWidth]);

  useEffect(() => {
    if (svgWrapperWidth && ganttChartData) {
      const numberOfEmployees = ganttChartData.employees.length;
      const columnWidthWithoutMinimumBoundary =
        ganttChartService.getVerticalColumnWidth(
          svgWrapperWidth,
          numberOfEmployees,
        );

      dispatch(
        setVerticalColumnWidth(
          columnWidthWithoutMinimumBoundary > 40
            ? columnWidthWithoutMinimumBoundary
            : 40,
        ),
      );
      // Update svgWidth to extend beyond wrapper width, if columns exceed minimum width
      if (verticalColumnWidth === 40) {
        dispatch(
          setVerticalSvgWidth(
            ganttChartService.getVerticalSvgWidth(
              numberOfEmployees,
              verticalColumnWidth,
            ),
          ),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgWrapperWidth, ganttChartData]);

  useEffect(() => {
    if (svgWrapper.current) {
      const verticalSvgWidth = svgWrapper.current?.clientWidth;
      dispatch(setVerticalSvgWidth(verticalSvgWidth));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  const touchPadZoom = (event: WheelEvent) => {
    if (!event.ctrlKey) return;

    event.preventDefault();
    const { deltaY, clientX, clientY } = event;
    dispatch(zoomInOnVerticalView(-deltaY));
  };

  useEffect(() => {
    const handleScroll = () => {
      if (svgWrapper.current) {
        const scrollTop = svgWrapper.current.scrollTop;
        if (scrollTop > lastScrollTop) {
          setIsSticky(true);
        } else if (scrollTop < lastScrollTop) {
          setIsSticky(false);
        }
        setLastScrollTop(scrollTop); // For Mobile or negative scrolling
      }
    };

    const currentWrapper = svgWrapper.current;
    if (currentWrapper) {
      currentWrapper.addEventListener("scroll", handleScroll);
      currentWrapper.addEventListener("scroll", (event) => {
        dispatch(hideTaskHoverBox());
      });
      // Try the MacOS zoom thing
      currentWrapper.addEventListener("wheel", touchPadZoom);
    }

    return () => {
      if (currentWrapper) {
        currentWrapper.removeEventListener("scroll", handleScroll);
        currentWrapper.removeEventListener("wheel", touchPadZoom);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollTop]);

  return (
    <>
      {ganttChartData && (
        <>
          <div className="relative w-full">
            <VerticalTitleTextRow
              title="medarb."
              employees={ganttChartData.employees}
            />

            <VerticalTextRows
              employees={ganttChartData.employees}
              isSticky={isSticky}
            />
          </div>
          <div
            ref={svgWrapper}
            id="vertical-ganttChart-svg-wrapper"
            style={{
              height: "100%",
              borderTop: "1px solid var(--col-grey)",
              width: `calc(100% + ${containerWidthOffset}px`, //hides scrollbar on y-axis when it appears.
            }}
            className={ganttChartStyles.verticalSvgWrapper}
          >
            {verticalSvgWidth && verticalColumnWidth && (
              <>
                <svg
                  id="vertical-ganttChart-svg"
                  width={verticalSvgWidth}
                  height={
                    pixelsPerHour && timelineRange
                      ? timelineRange?.fullDayHours * pixelsPerHour
                      : 100
                  }
                  style={{
                    transform: `translateX(-${translateXValueInPercent * verticalSvgWidth}px)`,
                    transition: "transform 250ms ease",
                  }}
                >
                  <g>
                    {timelineRange && (
                      <BackgroundColumns
                        verticalColumnWidth={verticalColumnWidth}
                        ganttChartData={ganttChartData}
                      />
                    )}

                    <SharedTimelineComponent />
                    <VerticalGanttChartContent />
                    {pixelsPerHour && verticalColumnWidth && (
                      <VerticalGanttChartSeparationLines
                        employees={ganttChartData.employees}
                        verticalColumnWidth={verticalColumnWidth}
                        pixelsPerHour={pixelsPerHour}
                      />
                    )}
                    {taskIsHovered && <HoverBox />}
                  </g>
                </svg>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default VerticalGanttWrapper;
