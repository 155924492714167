import labels from "./labels";
import { WorkTask } from "../types/ganttChart";

const getViolationStrings = (task: WorkTask): string[] => {
  const violationStrings = task.violations.map(
    (violationItem) => violationItem["name"],
  );

  return violationStrings.map((violationString) =>
    getViolationMessagesFromViolationName(violationString),
  );
};

export const getViolationMessagesFromViolationName = (violation: string) => {
  switch (violation) {
    case "SKILL":
      return labels.violationSkill;
    case "DEPENDENCY":
      return labels.violationDependency;
    case "DEPENDENCY_MIN_OVERLAP":
      return labels.violationDependency;
    case "DEPENDENCY_SAME_EMPLOYEE":
      return labels.violationDependencySameEmployee;
    case "OVERDUE":
      return labels.violationOverdue;
    case "OVERTIME":
      return labels.violationOvertime;
    case "TRAVEL_TOTAL_DISTANCE":
      return labels.violationTravelTotalDistance;
    case "TRAVEL_TRIP_DISTANCE":
      return labels.violationTravelTripDistance;
    case "TRAVEL_FIRST_LAST_TRIP_DISTANCE":
      return labels.violationTravelFirstLastTripDistance;
    default:
      return violation;
  }
};

export class RuleConcordanceMessageFactory {
  private readonly task: WorkTask;

  constructor(task: WorkTask) {
    this.task = task;
  }

  /**
   * Loops through the task dependencies and violations and generates the requisite messages
   */
  getMessages = (): string[] => {
    const messages: string[] = [];
    for (const dependency of this.task.taskDependencies) {
      if (dependency.maxDelayTime && dependency.maxDelayAnchor)
        messages.push(
          labels.maxDelayTimeViolationMessage(
            dependency.maxDelayTime.toString(),
            dependency.maxDelayAnchor as "START" | "END",
          ),
        );

      if (dependency.minDelayTime && dependency.minDelayAnchor)
        messages.push(
          labels.maxDelayTimeViolationMessage(
            dependency.minDelayTime.toString(),
            dependency.minDelayAnchor as "START" | "END",
          ),
        );

      if (dependency.minOverlap) {
        messages.push(
          labels.dependencyMinOverlapViolationMessage(
            dependency.minOverlap.toString(),
          ),
        );
      }

      if (dependency.sameEmployee)
        messages.push(labels.sameEmployeeViolationMessage);

      if (dependency.startRightAfter)
        messages.push(labels.startRightAfterViolationMessage);

      if (dependency.sameStartTime)
        messages.push(labels.sameStartViolationMessage);
    }

    // Get any violation messages if necessary
    const violationMessages = this.task.violations
      .filter(
        (violation) =>
          // Filter out min overlap violations since this is handled by the minOverlap parameter in the dependency object
          "name" in violation && violation.name !== "DEPENDENCY_MIN_OVERLAP",
      )
      .map((violation) => {
        switch (violation.name) {
          case "SKILL":
            return labels.violationSkill;
          case "OVERDUE":
            return labels.violationOverdue;
          case "OVERTIME":
            return labels.violationOvertime;
          default:
            return labels.unspecifiedViolationMessage;
        }
      });
    if (violationMessages.length > 0)
      violationMessages.forEach((msg) => messages.push(msg));

    return messages;
  };
}

export default getViolationStrings;
