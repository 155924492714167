import {
  GanttTabBarTab,
} from "../types/ganttChart";
import { FullTimelineRange } from "../types/timeline";

class ganttCHartValues {
  // ####### HORIZONTAL VIEW #########
  // Rows
  static rowHeightForBoth = 80;
  static rowHeightForBothString = "80px";
  static rowHeight = 40;
  static rowHeightString = "40px";
  static expandedRowHeight = 60;
  static expandedRowHeightString = "60px";
  static headerRowHeight = 40;
  static expandedUnplannedRowHeight = 60;
  static individualUnplannedRowHeight = 30;

  // Columns
  static nameColumnWidth = 200;
  static nameColumnWidthString = "300px";

  // Tasks
  static individualTaskHeight = 20;
  static expandedIndividualTaskHeight = 40;
  static taskTextLineHeight = 18;
  // Todo: change
  // Colors
  static mochaColor = "var(--col-primary)";
  static mochaColor40 = "var(--col-primary-40)";

  // Movement params
  static zoomInHours = 3;
  static jumpInHours = 0.5;

  // Tab naviagtion
  static getTabLabel(type: GanttTabBarTab) {
    switch (type) {
      case "Both":
        return "Begge";
      default:
        return type;
    }
  }
}
export default ganttCHartValues;

export class ganttChartVerticalValues {
  // ####### VERTICAL VIEW #########
  static verticalViewHeaderColumn = 76;
  static verticalViewMinColumnWidth = 40;
  static verticalViewTextRowHeight = 34;
  static verticalViewColumn = 34;
  static allowedNumberOfColumnIncludingUnPlanned = 50;
  static verticalGanttChartHeight = 720;
  static verticalSvgHeight =
    this.verticalGanttChartHeight + this.verticalViewTextRowHeight * 4;

  // Movement params
  static zoomInHoursOnVertical = 3;
  static jumpInHoursOnVertical = 1;

  static getTaskWidth = (verticalColumnWidth: number) => {
    return verticalColumnWidth! >= this.verticalViewMinColumnWidth
      ? verticalColumnWidth! - 4
      : this.verticalViewMinColumnWidth - 4;
  };

  static findTaskYPosition = (
    timelineRange: FullTimelineRange,
    pixelPerMinute: number,
    starttime: string
  ) => {
    // Set the firstHourOfDay to equal midnat or whenever the day is set to begin - as in calendar day.
    const firstHourOfDay = new Date(timelineRange!.chosenDate);
    firstHourOfDay.setHours(timelineRange!.firstHourInFullDay);
    firstHourOfDay.setMinutes(0);
    const differenceInMiliseconds =
      new Date(starttime).getTime() - firstHourOfDay.getTime();
    const differenceInMinutes = differenceInMiliseconds / 60000;

    return differenceInMinutes * pixelPerMinute;
  };
}
