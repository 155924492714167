import wrapperStyles from "../../styles/UI/wrappers.module.css";

const ColouredRoundWrapper = ({
  children,
  classes,
  bgColor,
  height,
  padding,
  color,
  width,
  onClick,
}: {
  children: React.ReactNode | React.ReactNode[];
  classes?: string;
  bgColor: string;
  height?: string;
  padding?: boolean;
  color?: string;
  width?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={`${wrapperStyles.colouredRoundWrapper} ${classes ? classes : null}`}
      style={{
        color: color,
        backgroundColor: bgColor,
        width: width ? width : "fit-content",
        height: height ? height : "40px", // update to utril variable
        padding: padding ? "var(--spacing-sm) var(--spacing-md)" : "none",
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default ColouredRoundWrapper;
