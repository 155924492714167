import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ganttChartStyles from "../../../styles/ganttChart/ganttChart.module.css";
import {
  hideTaskHoverBox,
  openDrawer,
  showTaskHoverBox,
} from "../../../store/drawer";
import { Employee, WorkTask } from "../../../types/ganttChart";
import { ganttChartVerticalValues } from "../../../utils/ganttChartValues";
import taskStyles from "../../../styles/ganttChart/tasks.module.css";
import DependencyChip from "../../UI/chips/DependencyChip";
import ViolationChip from "../../UI/ViolationChip";
import { Fragment } from "react";
import { setHighlightedTasks } from "../../../store/search";

const VerticalWorkTask = ({
  xPosition,
  yPosition,
  width,
  task,
  height,
  employee,
}: {
  xPosition: number;
  yPosition: number;
  width: number;
  task: WorkTask;
  height?: number;
  employee?: Employee;
}) => {
  const dispatch = useAppDispatch();
  const { verticalColumnWidth } = useAppSelector(
    (state) => state.ganttChartSlice,
  );
  const highlightedTaskIds = useAppSelector(
    (state) => state.searchSlice.highlightedTaskIds,
  );
  const { pixelPerMinute, timelineRange } = useAppSelector(
    (state) => state.timelineSlice,
  );

  const allowedNumberOfLinesOfText = Math.floor((height! - 5) / 15);

  const isHighlightedByLink = (): boolean => {
    const dependencyIds = task.taskDependencies.flatMap((td) => [
      td.idPrerequisiteTask,
      td.idDependentTask,
    ]);
    return dependencyIds.some((id) => highlightedTaskIds.includes(id));
  };

  const getOpacity = () => {
    const isHighlightedOrEverythingShouldBeVisible =
      highlightedTaskIds.includes(task.idTask) ||
      highlightedTaskIds.length === 0;
    return isHighlightedOrEverythingShouldBeVisible ? 1 : 0.2;
  };
  return (
    <>
      {verticalColumnWidth && height && (
        <>
          {task.taskStatusCode === "VALIDATION_FAILED" && (
            // Todo: add hover
            <rect
              onClick={() => {
                dispatch(
                  openDrawer({ task: task, employeeName: employee?.fullName }),
                );
              }}
              width={width}
              height={height}
              rx={5}
              fill="var(--col-dirt-rose-20)"
              x={xPosition + 2}
              y={ganttChartVerticalValues.findTaskYPosition(
                timelineRange!,
                pixelPerMinute,
                task.earliestStart,
              )}
            />
          )}
          <foreignObject
            x={xPosition + 2}
            y={yPosition}
            width={width}
            height={height}
            onMouseEnter={() => {
              dispatch(
                showTaskHoverBox({
                  type: "WORKTASK",
                  task: task,
                  employee: employee,
                }),
              );
            }}
            onMouseLeave={() => {
              dispatch(hideTaskHoverBox());
            }}
          >
            <div
              onClick={() => {
                // Todo: figure out a way to implement a sounder click-away pattern for taskDependencis.
                if (isHighlightedByLink()) {
                  dispatch(setHighlightedTasks([]));
                } else
                  dispatch(
                    openDrawer({
                      task: task,
                      employeeName: employee?.fullName,
                    }),
                  );
              }}
              className={taskStyles.verticalWorkTask}
              style={{
                borderLeft:
                  task.taskStatusCode === "VALIDATION_FAILED"
                    ? "4px solid var(--col-dirt-rose-dark)"
                    : "4px solid var(--col-after-eight)",
                borderBottom:
                  task.taskStatusCode === "VALIDATION_FAILED"
                    ? "1px solid var(--col-dirt-rose-dark)"
                    : "1px solid var(--col-after-eight)",
                backgroundColor: "var(--col-after-eight-30)",
                opacity: getOpacity(),
              }}
            >
              {allowedNumberOfLinesOfText > 0 && (
                <p className={taskStyles.verticalTaskText}>
                  <b>{task.client.description}</b>
                </p>
              )}
              {allowedNumberOfLinesOfText > 1 && task.subTasks && (
                <ul className={taskStyles.taskList}>
                  {task.subTasks.map((task, index) => (
                    <Fragment key={index}>
                      {allowedNumberOfLinesOfText > index + 1 && (
                        <li className={taskStyles.verticalTaskText}>
                          <div className={ganttChartStyles.bulletPoint}></div>
                          {task.description}
                        </li>
                      )}
                    </Fragment>
                  ))}
                </ul>
              )}
              {/* Todo: come back to, when design has made prioritization rules. */}
              <div className="flex flex-wrap gap-[var(--spacing-xs)] absolute bottom-[var(--spacing-sm)]">
                {task.violations && task.violations.length > 0 && (
                  <ViolationChip></ViolationChip>
                )}
                {task.taskDependencies && task.taskDependencies.length > 0 && (
                  <DependencyChip
                    dependencyArray={task.taskDependencies}
                    key={task.idTask}
                    employeeName={employee!.fullName}
                  />
                )}
              </div>
            </div>
          </foreignObject>
        </>
      )}
    </>
  );
};

export default VerticalWorkTask;
