import { useEffect } from "react";
import ganttChartService from "../../../../service/ganttChartService";
import { TimeRange } from "../../../../types/ganttChart";
import dateUtil from "../../../../utils/dummeDate";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setTimelineArrayOfTimeStrings,
  setTimelineRange,
} from "../../../../store/timeline";
import VerticalGanttChartTimelineColumn from "../../verticalGanttChart/VerticalGanttChartTimelineColumn";
import Timeline from "./Timeline";
import timelineService from "../../../../service/timelineService";

const SharedTimelineComponent = () => {
  const { ganttChartDirection, ganttChartData, selectedGanttChartTab } =
    useAppSelector((state) => state.ganttChartSlice);
  const { selectedNexusFilterDate } = useAppSelector(
    (state) => state.nexusFiltersSlice
  );
  const dispatch = useAppDispatch();

  const { timelineRange } = useAppSelector((state) => state.timelineSlice);

  useEffect(() => {
    if (ganttChartData && selectedNexusFilterDate) {
      const selectedDate = new Date(selectedNexusFilterDate);
      const timeRange: TimeRange = ganttChartService.sortAccordingToStartDate(
        ganttChartData.employees,
        ganttChartData.generalGanttChartInfo.unplannedTasks,
        selectedGanttChartTab
      );
      const fullWorkDayInHours =
        //@ts-ignore
        (new Date(timeRange.endDate) -
          new Date(selectedNexusFilterDate).setHours(0, 0, 0, 0)) /
        (1000 * 60 * 60);

      dispatch(
        setTimelineRange({
          chosenDate: selectedDate.toISOString(),
          firstVisibleHour: timelineService.getFirstVisibleHour(
            timeRange.startDate
          ),
          lastVisibleHour: timeRange.endDate,
          fullDayHours: fullWorkDayInHours + 1,
          firstHourInFullDay: 0,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ganttChartData]);

  useEffect(() => {
    if (timelineRange) {
      dispatch(
        setTimelineArrayOfTimeStrings(
          dateUtil.generateTimelineData(timelineRange)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineRange]);

  return (
    <>
      {ganttChartDirection === "HORIZONTAL" && <Timeline />}
      {ganttChartDirection === "VERTICAL" && (
        <VerticalGanttChartTimelineColumn />
      )}
    </>
  );
};

export default SharedTimelineComponent;
